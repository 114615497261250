



















import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import coreApiWorkerClient from "@/services/apis/coreApiWorkerClient";
import makeSelectOptions from "@common/util/makeSelectOptions";
import orderTelesaleStatusesEn from "@/constants/orderTelesaleStatusesEn";
import orderLocationStatusesEn from "@/constants/orderLocationStatusesEn";
import orderCustomerRefusedStatusesEn from "@/constants/orderCustomerRefusedStatusesEn";
import userManager from "@/services/userManager";
import ProductOrCombo from "./partials/ProductOrCombo.vue";

export default Vue.extend({
  components: {
    ProductOrCombo,
  },
  data() {
    return {
      self: this,
      market: null,
      tableOptions: null,
    };
  },
  methods: {
    makeTableOptions(market) {
      return {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true,
          "show-select": true,
        },
        content: {
          urlEnabled: true,
          search: {
            ext: {
              hidden: true,
            },
          },
          displayFields: {
            orderId: {
              text: "Order ID",
              sortable: true,
              width: "100px",
            },
            createdTime: {
              text: "Date Order",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            callTime: {
              text: "Call Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            productOrCombo: {
              text: "Product / Combo",
            },
            giftedProduct: {
              text: "Gifted Product",
              options: {
                subProp: "giftedProduct.sku",
                label: true,
                sortBy: "giftedProduct.name",
              },
            },
            singlePrice: {
              text: "COD Price",
              options: {
                preTransform(_, item) {
                  let value =
                    (item.product && item.product.localPrice) ||
                    (item.productCombo && item.productCombo.overriddenLocalPrice) ||
                    item.productCombo?.localPrice;
                  if (isNaN(value)) {
                    value = 0;
                  }
                  return value;
                },
                filter: "currency",
                transform(value) {
                  return `${value} ${market.currency}`;
                },
              },
            },
            count: {
              text: "Count",
              sortable: true,
            },
            totalPrice: {
              text: "Total Price",
              options: {
                preTransform(_, item) {
                  let value =
                    (item.product && item.product.localPrice) ||
                    (item.productCombo && item.productCombo.overriddenLocalPrice) ||
                    item.productCombo?.localPrice;
                  if (isNaN(value)) {
                    value = 0;
                  }
                  return value * item.count;
                },
                filter: "currency",
                transform(value) {
                  return `${value} ${market.currency}`;
                },
              },
            },
            customerName: {
              text: "Customer's Name",
              sortable: true,
            },
            customerEmail: {
              text: "Email",
              sortable: true,
            },
            customerPhone: {
              text: "Phone Number",
              sortable: true,
            },
            customerMessage: {
              text: "Message",
              sortable: true,
            },
            customerState: !userManager.checkRole(["content", "design"], true) && {
              text: "State",
            },
            customerCity: !userManager.checkRole(["content", "design"], true) && {
              text: "City",
            },
            customerDistrict: !userManager.checkRole(["content", "design"], true) && {
              text: "District",
            },
            customerWard: !userManager.checkRole(["content", "design"], true) && {
              text: "Ward",
            },
            customerAddress: !userManager.checkRole(["content", "design"], true) && {
              text: "Address",
            },
            customerPostalCode: !userManager.checkRole(["content", "design"], true) && {
              text: "Postal Code",
            },
            telesaleUser: {
              text: "User Telesale",
              options: {
                subProp: "telesaleUser.name",
                sortBy: "telesaleUserId",
                attrs(_, item) {
                  const attrs: any = {};
                  if (item.telesaleUser && item.telesaleUser.ht === "nv") {
                    attrs.class = "red--text";
                  }
                  return attrs;
                },
              },
            },
            telesaleStatus: {
              text: "Calling Status",
              sortable: true,
              options: {
                label(value) {
                  return orderTelesaleStatusesEn[value || ""];
                },
              },
            },
            locationStatus: {
              text: "Address Status",
              sortable: true,
              options: {
                label(value) {
                  return orderLocationStatusesEn[value || ""];
                },
              },
            },
            locationNote: {
              text: "Address Note",
            },
            transportTrackingNumber: {
              text: "Tracking Number",
            },
            customerRefusedStatus: {
              text: "Cancel Reason",
              sortable: true,
              options: {
                label(value) {
                  return orderCustomerRefusedStatusesEn[value || ""];
                },
              },
            },
            notAnswerCallCount: {
              text: "Not Answer Count",
            },
            telesaleNote: {
              text: "Telesale Note",
            },
            action: {},
          },
          filters: {
            colLength: 2,
            filters: {
              orderId: {
                attrs: {
                  label: "Order ID",
                },
                rules: {
                  equal_to: {},
                },
              },
              "productItems.product._id": {
                type: "XAutocomplete",
                attrs: {
                  label: "Product",
                  "item-value": "_id",
                  "item-text": "sku",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("products", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: market.countryId,
                              },
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              "productCombos._id": {
                type: "XAutocomplete",
                attrs: {
                  label: "Combo",
                  "item-value": "_id",
                  "item-text": "name",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("productCombos", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "marketId",
                                operator: "equal_to",
                                value: market._id,
                              },
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              "productItems.product.productGroupId": {
                type: "XAutocomplete",
                attrs: {
                  label: "Product Group",
                  "item-value": "_id",
                  "item-text": "sku",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("productGroups", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: market.countryId,
                              },
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              createdTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Date Order",
                },
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Date Order (Start)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.startOf("day"),
                        },
                      },
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Date Order (End)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.endOf("day"),
                        },
                      },
                    },
                  },
                },
              },
              callTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Call Time",
                },
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Call Time (Start)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.startOf("day"),
                        },
                      },
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Call Time (End)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.endOf("day"),
                        },
                      },
                    },
                  },
                },
              },
              customerName: {
                attrs: {
                  label: "Customer's Name",
                },
                rules: {
                  equal_to: {},
                },
              },
              customerEmail: {
                attrs: {
                  label: "Email",
                },
                rules: {
                  equal_to: {},
                },
              },
              customerPhone: {
                attrs: {
                  label: "Phone Number",
                },
                rules: {
                  equal_to: {},
                },
              },
              customerMessage: {
                attrs: {
                  label: "Message",
                },
                rules: {
                  equal_to: {},
                },
              },
              locationNoteExist: {
                type: "select",
                attrs: {
                  label: "Address Note Exists",
                  items: [
                    { value: "", text: "Trống" },
                    { value: "yes", text: "Có" },
                    { value: "no", text: "Không" },
                  ],
                },
                rules: {
                  custom: {
                    filterMaker(value) {
                      if (value === "yes") {
                        return [
                          {
                            key: "locationNote",
                            operator: "not_equal_to",
                            value: "",
                          },
                          {
                            key: "locationNote",
                            operator: "has_property",
                          },
                        ];
                      } else if (value === "no") {
                        return {
                          operator: "or",
                          subFilters: [
                            {
                              key: "locationNote",
                              operator: "equal_to",
                              value: "",
                            },
                            {
                              key: "locationNote",
                              operator: "not_has_property",
                            },
                          ],
                        };
                      }
                      return [];
                    },
                  },
                },
              },
              telesaleUserId: {
                type: "XAutocomplete",
                attrs: {
                  label: "User Telesale",
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("users", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return [
                          {
                            _id: "",
                            name: "Trống",
                          },
                        ].concat(items);
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
                allowEmpty: true,
              },
              telesaleStatus: {
                type: "selectMultiple",
                attrs: {
                  label: "Calling Status",
                  items: makeSelectOptions(orderTelesaleStatusesEn),
                },
                rules: {
                  in: {},
                },
              },
              telesaleNote: {
                attrs: {
                  label: "Telesale Note",
                },
                rules: {
                  equal_to: {},
                },
              },
              notAnswerCallCount: {
                type: "integer",
                attrs: {
                  label: "Not Answer Count",
                },
                rules: {
                  equal_to: {},
                },
              },
              locationStatus: {
                type: "selectMultiple",
                attrs: {
                  label: "Address Status",
                  items: makeSelectOptions(orderLocationStatusesEn),
                },
                rules: {
                  in: {},
                },
              },
              customerRefusedStatus: {
                type: "selectMultiple",
                attrs: {
                  label: "Cancel Reason",
                  items: [
                    {
                      text: "Trống",
                      value: "",
                    },
                  ].concat(makeSelectOptions(orderCustomerRefusedStatusesEn)),
                },
                rules: {
                  in: {},
                },
              },
              customerMessageExist: {
                type: "select",
                attrs: {
                  label: "Customer Message Exists",
                  items: [
                    { value: "", text: "Trống" },
                    { value: "yes", text: "Có" },
                    { value: "no", text: "Không" },
                  ],
                },
                rules: {
                  custom: {
                    filterMaker(value) {
                      if (value === "yes") {
                        return [
                          {
                            key: "customerMessage",
                            operator: "not_equal_to",
                            value: "",
                          },
                          {
                            key: "customerMessage",
                            operator: "has_property",
                          },
                        ];
                      } else if (value === "no") {
                        return {
                          operator: "or",
                          subFilters: [
                            {
                              key: "customerMessage",
                              operator: "equal_to",
                              value: "",
                            },
                            {
                              key: "customerMessage",
                              operator: "not_has_property",
                            },
                          ],
                        };
                      }
                      return [];
                    },
                  },
                },
              },
              mktUserId: {
                type: "XAutocomplete",
                attrs: {
                  label: "User MKT",
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("users", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                              {
                                key: "roles",
                                operator: "in",
                                value: ["mkt", "tp", "rd", "rdl"],
                              },
                            ],
                          }),
                        });
                        return [
                          {
                            _id: "",
                            name: "Trống",
                          },
                        ].concat(items);
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              customerIpCoincide: !market.tmdt &&
                !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) && {
                  type: "boolean",
                  attrs: {
                    label: "IP trùng so với tất cả các đơn hàng",
                  },
                  rules: {
                    equal_to: {},
                  },
                },
            },
          },
          topActionButtons: {
            export: userManager.checkRole(["kt", "vdl", "vd"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Export Excel",
                icon: "mdi-microsoft-excel",
              },
              on: {
                xClick({ self }) {
                  const dataTable = self.context();
                  let findAllOptions = dataTable.getFindAllOptions();
                  if (findAllOptions.extraParams) {
                    findAllOptions.extraParams = {};
                  }
                  findAllOptions.extraParams.fieldSet = "telesale";
                  findAllOptions.extraParams.marketCode = market.code;

                  if (dataTable.model.value.length) {
                    findAllOptions = {
                      payload: JSON.stringify({
                        filters: [
                          {
                            key: "_id",
                            operator: "in",
                            value: dataTable.model.value.map((item) => item._id),
                          },
                        ],
                        limit: -1,
                        extraParams: {
                          fieldSet: "telesale",
                        },
                      }),
                    };
                  }
                  coreApiWorkerClient.download("orders", `@/export`, findAllOptions);
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["kt", "telesale", "vdl", "vd"]) && {
                target: {
                  dialog: {
                    attrs: {
                      width: "80%",
                    },
                    content: {
                      content: {
                        xOptions: {
                          content: {
                            colLength: 4,
                          },
                        },
                      },
                      buttons: {
                        cancel: {
                          content: {
                            text: "Close",
                          },
                        },
                        save: {
                          content: {
                            text: "Update",
                          },
                        },
                      },
                    },
                    ext: {
                      subTitleMaker: "orderId",
                    },
                  },
                },
              },
            },
          },
          findAllOptionsFilter(options) {
            options.filters.push({
              key: "marketId",
              operator: "equal_to",
              value: this.context().$route.params.marketId,
            });
            options.filters.push({
              key: "status",
              operator: "equal_to",
              value: "dxk",
            });
            options.filters.push({
              key: "telesaleConfirmed",
              operator: "not_equal_to",
              value: true,
            });
            options.filters.push({
              key: "shippingStatus",
              operator: "not_in",
              value: ["delivered", "onReturn", "returned"],
            });
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("orders", "findAll", options);
              return [items, count];
            },
            async update(item) {
              return await coreApiClient.call(
                "orders",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                  updateRequest: {
                    scenario: "TelesaleConfirmItemUpdate",
                  },
                }
              );
            },
          },
          default: {
            defaultInputs: {
              productOrCombo: {
                attrs: {
                  label: "Product / Combo",
                  readonly: true,
                  xOptions: {
                    type: ProductOrCombo,
                    attrs(value, _item, self) {
                      const item = self.context().context().xData.value;
                      return {
                        item,
                      };
                    },
                  },
                },
                content: {
                  static: true,
                },
                ext: {
                  colLength: 6,
                },
              },
              orderId: {
                attrs: {
                  label: "Order ID",
                  readonly: true,
                },
              },
              customerName: {
                attrs: {
                  label: "Customer's Name",
                  readonly: true,
                },
              },
              customerPhone: {
                attrs: {
                  label: "Phone Number",
                  readonly: true,
                },
              },
              createdTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Date Order",
                  xOptions: {
                    content: {
                      dpAttrs: {
                        readonly: true,
                      },
                    },
                  },
                },
              },
              singlePrice: {
                attrs: {
                  label: "COD Price",
                  readonly: true,
                },
                ext: {
                  valueGetter(item) {
                    let value =
                      (item.product && item.product.localPrice) ||
                      (item.productCombo && item.productCombo.overriddenLocalPrice) ||
                      item.productCombo?.localPrice;
                    if (isNaN(value)) {
                      value = 0;
                    }
                    return value;
                  },
                },
              },
              telesaleNote: {
                attrs: {
                  label: "Telesale Note",
                },
              },
              locationNote: {
                attrs: {
                  label: "Address Note",
                  readonly: true,
                },
              },
              customerState: {
                attrs: {
                  label: market.code.toLowerCase() === "ph" ? "Province" : "State",
                  readonly: true,
                },
              },
              customerDistrict: {
                attrs: {
                  label: "City",
                  readonly: true,
                },
              },
              customerWard: {
                attrs: {
                  label: market.code.toLowerCase().includes("ph") ? "Barangay" : "Ward",
                  readonly: true,
                },
              },
              customerAddress: {
                attrs: {
                  label: "Detailed Address",
                  readonly: true,
                },
              },
              customerPostalCode: {
                attrs: {
                  label: "Postale Code",
                  readonly: true,
                },
              },
              transportTrackingNumber: {
                attrs: {
                  label: "Tracking Number",
                  readonly: true,
                },
              },
              productItems: {
                type: "XArrayInput",
                attrs: {
                  label: "Product items",
                  readonly: true,
                  xOptions: {
                    content: {
                      itemLabel: (item, i) => (item.product && item.product.sku) || `Sản phẩm ${i + 1}`,
                      itemProperties: {
                        product: {
                          type: "XAutocomplete",
                          attrs: {
                            label: "Sản phẩm",
                            "item-value": "_id",
                            "item-text": "sku",
                            "return-object": true,
                            clearable: true,
                            xOptions: {
                              content: {
                                async itemsSuggestor(search, value, limit) {
                                  const { items } = await coreApiClient.call("products", "findAll", {
                                    payload: JSON.stringify({
                                      search,
                                      limit,
                                      filters: [
                                        {
                                          key: "countryId",
                                          operator: "equal_to",
                                          value: market.countryId,
                                        },
                                        {
                                          key: "_id",
                                          operator: "equal_to",
                                          value: value && value._id,
                                        },
                                      ],
                                    }),
                                  });
                                  return items;
                                },
                              },
                            },
                          },
                        },
                        count: {
                          type: "integer",
                          attrs: {
                            label: "Số lượng",
                            required: true,
                          },
                        },
                      },
                      template: {
                        formOptions: {
                          content: {
                            colLength: 6,
                          },
                        },
                      },
                    },
                  },
                },
                ext: {
                  colLength: 6,
                },
              },
              overriddenSinglePrice: {
                type: "number",
                attrs: {
                  label: "Overridden Single Price",
                  readonly: true,
                },
              },
              telesaleConfirmed: {
                type: "boolean",
                attrs: {
                  label: "Telesale Confirmed",
                },
              },
            },
            editForm: userManager.checkRole(["kt", "telesale", "vdl", "vd"]) && {
              content: {
                fieldNames: [
                  "productOrCombo",
                  "createdTime",
                  "singlePrice",

                  "orderId",
                  "customerName",
                  "customerPhone",

                  "customerState",
                  "customerDistrict",
                  "customerWard",
                  "customerAddress",

                  "customerPostalCode",
                  "transportTrackingNumber",

                  "telesaleNote",
                  "locationNote",

                  "productItems",
                  "overriddenSinglePrice",

                  "telesaleConfirmed",
                ],
                colLength: 3,
              },
            },
          },
        },
      };
    },
  },
  async created() {
    this.market = await coreApiClient.call("markets", "findById", {
      id: this.$route.params.marketId,
    });
    this.tableOptions = this.makeTableOptions(this.market);
  },
});
